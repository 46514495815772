import jQuery from 'jquery';

require("./drag-and-drop.js");

( ( $ ) => {

    let site = {

        bootstrap: () => {

            site.initModal();

        },

        initModal: () => {

            $("[data-modal]").on( 'click', function() {
                let $modal = $( $(this).attr("data-modal") );
                $modal.fadeIn(500);
            });

            $(".modal-close, .modal-overlay").on( 'click', function() {
                $(this).closest(".modal").fadeOut(500);
            });

        }

    }

    $( document ).ready( site.bootstrap );

} )(jQuery);