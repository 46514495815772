import $ from 'jquery';
const interact = require ('interactjs');

const tireConfig = {
    bmw: {
        frontTire: {
            top: 43.20, // in Prozent zum Bild
            left: 57.41, // in Prozent zum Bild
            width: 15.2, // in Prozent zum Bild
            scaleX: 1
        },
    
        rearTire: {
            top: 41.03, // in Prozent zum Bild
            left: 11.42, // in Prozent zum Bild
            width: 12.87,  // in Prozent zum Bild
            scaleX: 0.8
        }
    },

    mini: {
        frontTire: {
            top: 45.75, // in Prozent zum Bild
            left: 53.91, // in Prozent zum Bild
            width: 14.59, // in Prozent zum Bild
            scaleX: 1
        },  
        rearTire: {
            top: 43.40, // in Prozent zum Bild
            left: 10.37, // in Prozent zum Bild
            width: 12.34,  // in Prozent zum Bild
            scaleX: 0.87
        }
    }
};

( function() {

    const dragAndDrop = {

        successFullMounted: 0,
        tireConfig: null,

        bootstrap: () => {

            let brand = $(".drag-and-drop").attr("data-brand");
            dragAndDrop.tireConfig = tireConfig[brand];


            dragAndDrop.positionPlaceholder();

            

            $(".tire").each( function() {

                $(this).data("x", 0);
                $(this).data("y", 0);

                dragAndDrop.setTireSize( $(this) );

                interact($(this).get(0)).draggable({

                    listeners: {

                        start (event) {
                            //console.log(event.type, event.target)
                            $(".fronttire-placeholder, .reartire-placeholder").not(".mounted").css("display", "block");
                        },
                        
                        move (event) {

                            // Nur aktiv, wenn weniger als 2 Reifen erfolgreich montiert wurden
                            if( dragAndDrop.successFullMounted >= 2 ) {
                                return;
                            }

                            let $tire = $(event.target);
                            
                            let tireTop = parseInt( $tire.css("top").replace("px", "") );
                            let tireLeft = parseInt( $tire.css("left").replace("px", "") );
                            
                            
                            let imageWidth = $(".drag-and-drop").width();
                            let imageHeight = $(".drag-and-drop").height();    

                            let left = ( tireLeft + event.dx ) / imageWidth * 100;
                            left = Math.min( left, 82 );
                            left = Math.max( left, 0 );

                            let top = ( tireTop + event.dy ) / imageHeight * 100;
                            top = Math.min( top, 73 );
                            top = Math.max( top, 0 );
                            
                            $tire.css("left", left + "%");
                            $tire.css("top", top + "%");

                            dragAndDrop.setTireSize( $tire );
                            
                        },

                        end( event) {
                            dragAndDrop.validate();
                        }

                    }
                })
            })
        },


        positionPlaceholder: () => {

            let frontTire = dragAndDrop.tireConfig.frontTire;
            let rearTire = dragAndDrop.tireConfig.rearTire;

            let frontTireHeight = frontTire.width * $(".drag-and-drop").width() / $(".drag-and-drop").height();
            let rearTireHeight = rearTire.width * $(".drag-and-drop").width() / $(".drag-and-drop").height();

            $(".fronttire-placeholder")
                .css("top", frontTire.top + "%")
                .css("left", frontTire.left + "%")
                .css("width", frontTire.width + "%")
                .css("height", frontTireHeight + "%")
                .css("transform", "scaleX(" + frontTire.scaleX + ")")
                .css("display", "none");
                
                $(".reartire-placeholder")
                .css("top", rearTire.top + "%")
                .css("left", rearTire.left + "%")
                .css("width", rearTire.width + "%")
                .css("height", rearTireHeight + "%")
                .css("transform", "scaleX(" + rearTire.scaleX + ")")
                .css("display", "none");

        },

        setTireSize: ( $tire ) => {

            let frontTire = dragAndDrop.tireConfig.frontTire;
            let rearTire = dragAndDrop.tireConfig.rearTire;

            /**
             * Verhältnis der Auswirkung der Größe zwischen Änderungen auf x und y-Achse
             * > 0.5 ... x-Achse hat mehr Einfluss auf die Größe als die y-Asche
             */
            let ratioX = 0.95;


            /**
             * Alles andere wird berechnet.
             */

            let ratioY = 1 - ratioX;

            /**
             * Skalierungsfaktor auf den Achsen:
             * Wie viel Prozent ändert sich die Breite für jedes bewegtes Prozent an der jeweiligen Achse?
             */
            let scaleFactorPerPixelX = ratioX * ( frontTire.width - rearTire.width ) / ( frontTire.left - rearTire.left );
            let scaleFactorPerPixelY = ratioY * ( frontTire.width - rearTire.width ) / ( frontTire.top - rearTire.top );
    

            let imageWidth = $(".drag-and-drop").width();
            let imageHeight = $(".drag-and-drop").height();

            let positionPercentX = $tire.css("left").replace("px","") / imageWidth * 100;
            let positionPercentY = $tire.css("top").replace("px", "") / imageHeight * 100;

            // Wir gehen vom Vorderreifen aus und berechnen der 
            let diffToFrontTireX = positionPercentX - frontTire.left;
            let diffToFrontTireY = positionPercentY - frontTire.top;

            let width = frontTire.width + scaleFactorPerPixelX * diffToFrontTireX + scaleFactorPerPixelY * diffToFrontTireY;

            //console.log(frontTire.width, scaleFactorPerPixelX, diffToFrontTireX, scaleFactorPerPixelY, diffToFrontTireY);

            $tire.css("width", width + "%");


            /**
             * Zusächlich zur Breite wird der Reifen noch verzerrt, weil der Betrachtungswinkel durch die Entfernung spitzer wird.
             */
            let transformFactorPerPixelX = ratioX * ( frontTire.scaleX - rearTire.scaleX ) / ( frontTire.left - rearTire.left );
            let transformFactorPerPixelY = ratioY * ( frontTire.scaleX - rearTire.scaleX ) / ( frontTire.top - rearTire.top );

            let transformX = frontTire.scaleX + transformFactorPerPixelX * diffToFrontTireX + transformFactorPerPixelY * diffToFrontTireY; 

            transformX = Math.min( 1, transformX ); // Soll nicht größer 1 werden

            $tire.css("transform", "scaleX(" + transformX + ")");
           
        },

        validate: () => {

            let threshold = 1; // in Prozent

            let frontTire = dragAndDrop.tireConfig.frontTire;
            let rearTire = dragAndDrop.tireConfig.rearTire;

            dragAndDrop.successFullMounted = 0;

            $(".reartire-placeholder, .fronttire-placeholder").removeClass("mounted")
            
            $(".tire").each( function() {

                let imageWidth = $(".drag-and-drop").width();
                let imageHeight = $(".drag-and-drop").height();

                let positionPercentX = $(this).css("left").replace("px","") / imageWidth * 100;
                let positionPercentY = $(this).css("top").replace("px", "") / imageHeight * 100;

                // Vorderreifen?
                if( Math.abs( positionPercentX - frontTire.left ) < threshold && Math.abs( positionPercentY - frontTire.top ) < threshold ) {
                    $(this).css("left", frontTire.left + "%").css("top", frontTire.top + "%");
                    $(this).css("transform", "scaleX(" + frontTire.scaleX + ")");
                    dragAndDrop.successFullMounted++;
                    $(".fronttire-placeholder").css("display", "none").addClass("mounted");
                }
                
                // Hinterreifen?
                if( Math.abs( positionPercentX - rearTire.left ) < threshold && Math.abs( positionPercentY - rearTire.top ) < 10 ) {
                    $(this).css("left", rearTire.left + "%").css("top", rearTire.top + "%");
                    $(this).css("transform", "scaleX(" + rearTire.scaleX + ")");
                    dragAndDrop.successFullMounted++;
                    $(".reartire-placeholder").css("display", "none").addClass("mounted");
                }

            })

            if( dragAndDrop.successFullMounted >= 2 ) {
                $(".competition").addClass("hidden");
                console.log($(".form"));
                $(".form").slideDown();
            }

        },

    };

    $(document).ready( dragAndDrop.bootstrap );

} )();